html {
  margin:0;
  overflow-x:hidden;
  padding:0
}

*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a{
  border:none;
}

a:hover{text-decoration:none;}

html,body,div,iframe,h3,p,a,ul,li
{
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: none;
}

ul{
  margin:0px;
  padding: 0;
}
ul li{
  margin:0px;
}

img{
  vertical-align:middle;
  max-width:100%;
}

img.svg {
  height:16px;
  width:16px
}

body {
  background-color:#fff;
  box-sizing:border-box;
  color:#767676;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  letter-spacing:.5px;
  word-wrap:break-word
}

h3 {
  color:#000;
  font-weight: 400;
  font-family: "Lora", sans-serif;
}

.main {
  box-sizing:border-box;
  position:relative;
  width:100%
}

.main .nav {
  align-items:center;
  background-color:#fff;
  position:fixed;
  width:100%;
  padding:24px 32px;
  z-index:1
}

.me {
  justify-content:center;
  min-height:100vh;
}

.me, .me .content {
  align-items:center;
  display:flex
}

.me .avatar {
  border-radius:100%;
  margin-bottom:24px;
  min-height:200px;
  min-width:200px;
  position:relative
}

.me .avatar .image {
  background-repeat:no-repeat;
  background-size:cover;
  border-radius:100%;
  bottom:0;
  box-shadow:inset 0 0 0 2px rgba(0,0,0,.1);
  left:0;
  overflow:hidden;
  position:absolute;
  right:0;
  top:0
}

.me .details .name {
  font-size:35px;
  font-weight:800;
  margin-bottom:16px;
}

.me .details .job {
  font-style:italic;
  margin-bottom: 16px;
}

.me .content {
  flex-direction:column;
  text-align:center
}

.me .details {
  margin-left:0
}

.me .social {
  float:left;
  width:100%
}

.social {
  list-style-type:none;
}

.social li {
  display:inline-block;
  margin:0 16px 0 0
}

.social li:last-child {
  margin-right:0
}

.social li a {
  -moz-transition:all .3s ease;
  -ms-transition:all .3s ease;
  -o-transition:all .3s ease;
  -webkit-transition:all .3s ease;
  color:#000;
  text-decoration:none;
  transition:all .3s ease
}

.social li a:hover {
  filter:opacity(.7)
}

.social li .svg {
  height:16px;
  width:16px
}
